<!--活动列表-->
<template>
  <div class="activity-list">

    <a-form-model ref="refForm" class="global-card-form" :model="filterForm" :inline="true">

      <a-form-model-item label="活动名称" prop="title">
        <a-input v-model="filterForm.activeName" size="large" placeholder="输入活动名称搜索" />
      </a-form-model-item>

      <a-form-model-item>
        <el-button type="primary" size="medium" @click="search({ page: 1, size })">搜 索</el-button>
        <el-button type="danger" size="medium" @click="reset({ page: 1, size })">重 置</el-button>
      </a-form-model-item>

    </a-form-model>

    <el-button class="add-activity" type="primary" size="medium" @click="addActivity">新增活动</el-button>

    <div class="page-box-overflow" v-loading="tableLoad">
      <a-table
        class="activity-table"
        rowKey="activityId"
        :columns="columns"
        :data-source="tableData"
        :pagination="false"
        bordered
        :scroll="{ x: '100%', y: 'calc(100% - 39px)' }"
      >

        <template slot="startTime" slot-scope="text, row">
          <span>{{ row.startTime }} - {{ row.endTime }}</span>
          <Tag style="margin-left: 12px;vertical-align: bottom" color="#87d068">{{ row.timeTag }}</Tag>
        </template>

        <template slot="stateDesc" slot-scope="text, row">
          <Tag :color="row.stateColor">{{ text }}</Tag>
        </template>

        <template slot="showHome" slot-scope="text, row">
          <a-switch v-model="row.showHome" @change="changeHome(row)" />
        </template>

        <template slot="operation" slot-scope="text, row">
          <Popover
              class="link-poptip"
              placement="right"
              width="200"
              trigger="click">
            <div v-loading="!showCodeImg" class="code-img">
              <img v-if="showCodeImg" class="code-img" :src="showCodeImg" alt="">
            </div>
            <el-button size="mini" type="success" slot="reference" @click="getImg(row)">查看小程序码</el-button>
          </Popover>
          <el-button size="mini" type="warning" @click="editActive(row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="del(row)">删除</el-button>
        </template>

      </a-table>
    </div>

    <GTablePagination :total="total" @changePage="pageInfo => search(pageInfo)" />

  </div>
</template>

<script>
import GTablePagination from '@/components/GTablePagination'
import { activityPage, activityUpdate, activityDel, activityQrCode } from '@/api/businessCard'
import { Tag, Switch } from 'ant-design-vue'
import { Popover } from 'element-ui'
export default {
  name: 'activityList',
  components: { GTablePagination, Tag, 'a-switch': Switch, Popover },
  data() {
    return {
      filterForm: { activeName: '' },
      page: 1,
      size: 20,
      columns: [
        { title: '序号', key: 'index', customRender: (text, record, index) => index + 1, width: 60, align: 'center' },
        {title: '活动名称', dataIndex: 'activityName', width: 140 },
        {title: '曝光量', dataIndex: 'activityPv', width: 80 },
        {title: '线索量', dataIndex: 'activityUv', width: 80},
        {title: '活动时间', dataIndex: 'startTime', scopedSlots: { customRender: 'startTime' }, width: 380 },
        {title: '状态', dataIndex: 'stateDesc', width: 100, scopedSlots: { customRender: 'stateDesc' }},
        {title: '默认活动', dataIndex: 'showHome', width: 100, scopedSlots: { customRender: 'showHome' }},
        {title: '版本', dataIndex: 'version', width: 100},
        {title: '创建人', dataIndex: 'createOperatorName', width: 120},
        {title: '创建时间', dataIndex: 'createTime', width: 160},
        {title:  '编辑', dataIndex: 'operation', width: 260, fixed: 'right', scopedSlots: { customRender: 'operation' }}
      ],
      tableData: [],
      total: 0,
      showCodeImg: '',
      tableLoad: false
    }
  },
  created() {
    this.search({ page: 1, size: this.size })
  },
  methods: {
    search(pageInfo) {
      this.page = pageInfo.page
      this.size = pageInfo.size
      this.tableLoad = true
      activityPage({
        ...this.filterForm,
        page: this.page,
        size: this.pageSize
      }).then(res => {
        this.tableLoad = false
        this.total = res.data.total
        this.tableData = res.data.list
      }).catch(() => this.tableLoad = false)
    },
    reset(pageInfo) {
      this.$refs.refForm.resetFields()
      this.search(pageInfo)
    },
    changeHome(item) {
      activityUpdate({
        activityId: item.activityId,
        showHome: item.showHome
      }).then(() => {
        this.$message.success('修改成功')
        this.search({ size: this.size, page: this.page })
      })
    },
    editActive(row) {
      this.$router.push({
        path: 'createCtivity',
        query: {
          editMode: 1,
          id: row.activityId
        }
      })
    },
    del(row) {
      const self = this
      this.$antConfirm({
        title: '删除活动',
        content: '删除后，活动及其数据均被删除，无法恢复。\n 是否确定删除此活动？',
        width: '500px',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          activityDel({ activityId: row.activityId }).then(res => {
            self.$message.success('删除成功')
            self.search({ size: self.size, page: 1 })
          })
        },
        onCancel() {}
      })
    },
    addActivity() {
      this.$router.push({
        path: 'createCtivity'
      })
    },
    // 获取二维码
    getImg(row) {
      this.showCodeImg = ''
      activityQrCode({
        activityId: row.activityId
      }).then(res => {
        this.showCodeImg = res.data.url
      })
    },
  }
};
</script>

<style lang="scss" scoped>
  .activity-list{
    width: 100%;
    height: 100%;
    padding: 12px;
    position: relative;
    .add-activity{
      position: absolute;
      right: 25px;
      top: 25px;
    }
    .page-box-overflow{
      border-radius: 4px;
      overflow: hidden;
      width: 100%;
      height: calc(100% - 128px);
      overflow-y: auto;
      margin-top: 12px;
      box-shadow: $cardShadow;
      background: #fff;
    }
    .global-card-form{
      width: 100%;
      background: #fff;
      box-shadow: $cardShadow;
      padding: 12px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      ::v-deep{
        .ant-row{ margin-bottom: 0; margin-right: 12px }
        .ant-form-item-label{ width: auto }
      }
    }
    .activity-table{
      width: 100%;
      height: 100%;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;
      ::v-deep{
        .ant-spin-nested-loading{
          width: 100%;
          height: 100%;
          .ant-spin-container{
            height: 100%;
            .ant-table{
              height: 100%;
              .ant-table-content{
                height: 100%;
                .ant-table-scroll, .ant-table-fixed-right{
                  height: 100%;
                  .ant-table-thead{
                    tr{
                      th{
                        padding: 8px;
                      }
                    }
                  }
                  .ant-table-tbody{
                    tr{
                      td{
                        padding: 8px;
                      }
                    }
                  }
                  .ant-table-body{
                    margin: 0;
                  }
                }
              }
            }
          }
        }
        .ant-table-placeholder{
          border-bottom: none;
        }
        .ant-table-column-title{
          color: #475669;
          font-size: 12px;
          border-color: #EBEEF5;
        }
      }
    }
  }
  .link-poptip{
    ::v-deep{
      .el-button{
        margin-right: 12px;
      }
    }
  }
  .code-img {
    display: block;
    width: 180px;
    height: 180px;
    img{
      display: block;
      width: 100%;
      height: 100%;
    }
  }
</style>
